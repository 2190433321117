import React from 'react';
import { FormattedMessage } from 'react-intl';

const AskOffer = () => (
    <section id="cta" className="cta" data-testid="AskOffer">
        <div className="container" data-aos="zoom-in">
            <div className="text-center">
                <h3><FormattedMessage id="cta.title" /></h3>
                <p><FormattedMessage id="cta.text" /></p>
                <a className="cta-btn scrollto" href="/#contact"><FormattedMessage id="cta.button1" /></a>
                <a className="cta-btn" href="tel:+40748133096"><FormattedMessage id="cta.button2" /></a>
            </div>
        </div>
    </section>
);

export default AskOffer;
