import React from "react";
// Core modules imports are same as usual
import { Pagination, Autoplay } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

const ExistingClients = () => (
  <section id="clients" className="clients" data-testid="ExistingClients">
    <div className="container" data-aos="zoom-in">
      <Swiper
        breakpoints={{
          "@0.75": {
            slidesPerView: 3,
            spaceBetween: 100,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 140,
          },
          "@1.50": {
            slidesPerView: 3,
            spaceBetween: 150,
          },
        }}
        slidesPerView={"auto"}
        loop={true}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          },
        }}
        speed={400}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          <img
            src="images/clients/newmark.jpg"
            className="img-fluid"
            alt="newmark"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="images/clients/ganzkk.jpg"
            className="img-fluid"
            alt="ganzkk"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="images/clients/bitloop.svg"
            className="light-background img-fluid"
            alt="bitloop"
          />
        </SwiperSlide>
        <div className="swiper-pagination"></div>
      </Swiper>
    </div>
  </section>
);

export default ExistingClients;
