import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef: any = React.createRef();

const FooterNewsletter = () => {
    const intl = useIntl();
    const form: any = useRef();
    const notify = (e: any) => {
        e.preventDefault();
        e.target.reset();
        recaptchaRef.current.execute();
    }

    const onChange = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue) {
            toast.warn(intl.formatMessage({ id: "footer.subscribed" }), {
                pauseOnHover: false,
                theme: "dark"
            });
            emailjs.sendForm('service_umyd82m', 'template_tozdnty', form.current, 'user_EUqV8wZZWhMH7rLwGAP1B')
                .then(function () {
                    //nothing to do here
                }, function () {
                    //nothing to do here
                });
        }
    }
    return (
        <div className="col-lg-3 col-md-6 footer-newsletter" data-testid="FooterNewsletter">
            <h4><FormattedMessage id="footer.join" /></h4>
            <p><FormattedMessage id="footer.jointext" /></p>
            <form ref={form} onSubmit={notify}>
                <input autoComplete="off" type="email" name="email" /><input type="submit" value={ intl.formatMessage({ id: "footer.subscribe" }) } />
                <ReCAPTCHA
                    theme="dark"
                    ref={recaptchaRef}
                    sitekey="6LdqP7cdAAAAAKPUze6WG6s4xf7hBt9Min0Yhf3I"
                    size="invisible"
                    onChange={onChange}
                />
            </form>
            <ToastContainer icon={false}/>
        </div>
    );
};

export default FooterNewsletter;
