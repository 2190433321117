import React, { useEffect } from 'react';
import PortfolioItem from './PortfolioItem/PortfolioItem';
import { FormattedMessage, useIntl } from "react-intl";
import GLightbox from 'glightbox';
import Isotope from 'isotope-layout';
import AOS from 'aos';

const Portfolio = () => {
    const intl = useIntl();

    const portfolio1: any = {
        id: 1, name: intl.formatMessage({ id: "portfolio.projects.pr1.title" }), type: intl.formatMessage({ id: "portfolio.projects.pr1.type" }), img: "/images/portfolio/desktop/ReportGenerator/img3.jpg", link: "details", filtertype: "filter-desktop"
    };

    const portfolio2: any = {
        id: 2, name: intl.formatMessage({ id: "portfolio.projects.pr2.title" }), type: intl.formatMessage({ id: "portfolio.projects.pr2.type" }), img: "/images/portfolio/mobile/adv/img3.jpg", link: "details", filtertype: "filter-mobile"
    };

    const portfolio3: any = {
        id: 3, name: intl.formatMessage({ id: "portfolio.projects.pr3.title" }), type: intl.formatMessage({ id: "portfolio.projects.pr3.type" }), img: "/images/portfolio/web/blog/img1.jpg", link: "details", filtertype: "filter-web"
    };


    /**
     * Easy selector helper function
     */

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all);
        if (selectEl) {
            if (all) {
                selectEl.forEach(e => e.addEventListener(type, listener));
            } else {
                selectEl.addEventListener(type, listener);
            }
        }
    };

    useEffect(() => {
        /**
         * Initiate portfolio lightbox 
         */
        GLightbox({
            selector: '.portfolio-lightbox'
        });

        /**
         * Porfolio isotope and filter
         */

        function portfoliosetup() {
            let portfolioContainer = select('.portfolio-container');
            if (portfolioContainer) {
                let portfolioIsotope = new Isotope(portfolioContainer, {
                    itemSelector: '.portfolio-item'
                });

                let portfolioFilters = select('#portfolio-flters li', true);

                on('click', '#portfolio-flters li', function (e) {
                    e.preventDefault();
                    portfolioFilters.forEach(function (el) {
                        el.classList.remove('filter-active');
                    });
                    this.classList.add('filter-active');

                    portfolioIsotope.arrange({
                        filter: this.getAttribute('data-filter')
                    });
                    portfolioIsotope.on('arrangeComplete', function () {
                        AOS.refresh();
                    });
                }, true);
            }
        }
        portfoliosetup();
        window.addEventListener('load', portfoliosetup);
        document.getElementById("all-projects").click();
    });

    return (
        <section id="portfolio" className="portfolio" data-testid="Portfolio">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2><FormattedMessage id="portfolio.projects.title" /></h2>
                    <p><FormattedMessage id="portfolio.projects.subtitle" /></p>
                </div>

                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li data-filter="*" id="all-projects" className="filter-active"><FormattedMessage id="portfolio.projects.all" /></li>
                            <li data-filter=".filter-desktop"><FormattedMessage id="portfolio.projects.desktop" /></li>
                            <li data-filter=".filter-web"><FormattedMessage id="portfolio.projects.web" /></li>
                            <li data-filter=".filter-mobile"><FormattedMessage id="portfolio.projects.mobile" /></li>
                        </ul>
                    </div>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                    <PortfolioItem {...portfolio1} />
                    <PortfolioItem {...portfolio2} />
                    <PortfolioItem {...portfolio3} />
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
