import React from 'react';
import TestimonialItem from './TestimonialItem/TestimonialItem';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useIntl } from 'react-intl';

const Testimonials = () => {
    const intl = useIntl();

    const testimonial1: any = {
        name: intl.formatMessage({ id: "quotes.1.name" }), jobTitle: intl.formatMessage({ id: "quotes.1.jobtitle" }), img: "/images/testimonials/jani.jpg", text: intl.formatMessage({ id: "quotes.1.quote" })
    };

    const testimonial2: any = {
        name: intl.formatMessage({ id: "quotes.2.name" }), jobTitle: intl.formatMessage({ id: "quotes.2.jobtitle" }), img: "/images/testimonials/zoli.jpg", text: intl.formatMessage({ id: "quotes.2.quote" })
    };

    const testimonial3: any = {
        name: intl.formatMessage({ id: "quotes.3.name" }), jobTitle: intl.formatMessage({ id: "quotes.3.jobtitle" }), img: "/images/testimonials/dennis.jpg", text: intl.formatMessage({ id: "quotes.3.quote" })
    };

    const testimonial4: any = {
        name: intl.formatMessage({ id: "quotes.4.name" }), jobTitle: intl.formatMessage({ id: "quotes.4.jobtitle" }), img: "/images/testimonials/nikki.jpg", text: intl.formatMessage({ id: "quotes.4.quote" })
    };

    return (
        <section id="testimonials" className="testimonials" data-testid="Testimonials">
            <div className="container" data-aos="zoom-in">
                <Swiper slidesPerView={'auto'} loop={true} pagination={{
                    el: '.swiper-pagination', type: 'bullets', clickable: true, renderBullet: function (index, className) {
                        return '<span class="' + className + '"></span>';
                    },
                }} speed={400} autoplay={{ delay: 5000, disableOnInteraction: false, }} modules={[Pagination, Autoplay]}>
                    <SwiperSlide><TestimonialItem {...testimonial1} /></SwiperSlide>
                    <SwiperSlide><TestimonialItem {...testimonial2} /></SwiperSlide>
                    <SwiperSlide><TestimonialItem {...testimonial3} /></SwiperSlide>
                    <SwiperSlide><TestimonialItem {...testimonial4} /></SwiperSlide>
                    <div className="swiper-pagination"></div>
                </Swiper>
            </div>
        </section>
    );
};

export default Testimonials;
