import React from 'react';
import { FormattedMessage } from "react-intl";

const Errorpage = () => (
    <div className="error-div" data-testid="Errorpage">
        <p><FormattedMessage id="errorpage.text" /></p>
        <img src="./images/error.png" alt="error" />
    </div>
);

export default Errorpage;
