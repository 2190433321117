import React from 'react';
import FooterCopyright from '../Footer/FooterCopyright/FooterCopyright'
import FooterSocialmedia from '../Footer/FooterSocialmedia/FooterSocialmedia'
import FooterInfo from '../Footer/FooterInfo/FooterInfo'
import FooterNewsletter from '../Footer/FooterNewsletter/FooterNewsletter'
import FooterLinks from '../Footer/FooterLinks/FooterLinks'
import ReactGA from 'react-ga'
import { getCookieConsentValue } from "react-cookie-consent";

const Footer = (props: any) => {

    if (getCookieConsentValue("dataconsent") === "true") {
        ReactGA.initialize('UA-217709602-1');
        ReactGA.pageview(window.location.pathname);
    }
    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    return (
        <footer id="footer" data-testid="Footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6">
                            <div className="footer-info">
                                <FooterInfo />
                                <FooterSocialmedia />
                            </div>
                        </div>
                        <FooterLinks hashnav={hashnav}/>
                        <FooterNewsletter />
                    </div>
                </div>
            </div>

            <FooterCopyright />
        </footer>
    );
};

export default Footer;
