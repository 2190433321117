import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import convertHtmlToReact from '@hedgedoc/html-to-react';

const FooterInfo = () => {
    const intl = useIntl();

    return (
        <div className="footerInfo" data-testid="FooterInfo">
            <a href="/" className="logo me-auto me-lg-0"><img src="/images/logoSD.svg" alt="" className="img-fluid" /></a>
            <p>
                <br />
                34A Main <FormattedMessage id="footer.street" /> <br />
                Long Eaton<br />
                NG10 1GR<br />
                <FormattedMessage id="footer.country" /><br /><br />
                <strong>{convertHtmlToReact(intl.formatMessage({ id: "footer.phone" }))}</strong> +<span className="ltrText">690 331 847 04</span><br />
                <span className="secondaryPhone">+<span className="ltrText">451 815 2247 44</span></span><br />
                <strong><FormattedMessage id="footer.email" />:</strong> <a href="mailto: &#x70;&#x6f;&#x70;&#x2e;&#x6c;&#x65;&#x76;&#x65;&#x6e;&#x74;&#x65;&#x40;&#x67;&#x6d;&#x61;&#x69;&#x6c;&#x2e;&#x63;&#x6f;&#x6d;"><span className="ltrText">moc.liamg@etnevel.pop</span></a><br />
            </p>
        </div>
    );
};

export default FooterInfo;
