import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useIntl } from "react-intl";
import convertHtmlToReact from '@hedgedoc/html-to-react';

const Privacypolicy = () => {
    const intl = useIntl();

    return (
        <div data-testid="Privacypolicy">
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p1" }))}
            </p>

            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p2" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title1" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p3" }))}
            </p>
            <ol className="privacy-policy-ol">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p4" }))}
            </ol>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p5" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title2" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p6" }))}
            </p>
            <ol className="privacy-policy-ol">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p7" }))}
            </ol>

            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p8" }))}
            </p>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p9" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title3" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p10" }))}
            </p>

            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p11" }))}
            </p>

            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p12" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title4" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p13" }))}
                <HashLink to="/cookiepolicy">{convertHtmlToReact(intl.formatMessage({ id: "menu.cookiepolicy" }))}.</HashLink>
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title5" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p14" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title6" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p15" }))}
            </p>

            <h2 className="privacy-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.title7" }))}
            </h2>
            <p className="privacy-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "privacypolicy.p16" }))}
            </p>

        </div >
    );
};

export default Privacypolicy;
