import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';
import { useParams } from "react-router-dom";
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useIntl } from 'react-intl';

const DetailsPage = (props: any) => {
    let params = useParams();
    const intl = useIntl();

    const projectDataDetails = [
        {
            name: intl.formatMessage({ id: "portfolio.projects.pr1.title" }),
            type: intl.formatMessage({ id: "portfolio.projects.pr1.type" }),
            client: "GanzKK",
            date: "2021",
            img1: "/images/portfolio/desktop/ReportGenerator/img1.jpg",
            img2: "/images/portfolio/desktop/ReportGenerator/img2.jpg",
            img3: "/images/portfolio/desktop/ReportGenerator/img3.jpg",
            description: intl.formatMessage({ id: "portfolio.projects.pr1.description" })
        },

        {
            name: intl.formatMessage({ id: "portfolio.projects.pr2.title" }),
            type: intl.formatMessage({ id: "portfolio.projects.pr2.type" }),
            client: "Anonymous ",
            date: "2021",
            img1: "/images/portfolio/mobile/adv/img1.jpg",
            img2: "/images/portfolio/mobile/adv/img2.jpg",
            img3: "/images/portfolio/mobile/adv/img3.jpg",
            description: intl.formatMessage({ id: "portfolio.projects.pr2.description" })
        },

        {
            name: intl.formatMessage({ id: "portfolio.projects.pr3.title" }),
            type: intl.formatMessage({ id: "portfolio.projects.pr3.type" }),
            client: "Anonymous",
            date: "2021",
            img1: "/images/portfolio/web/blog/img1.jpg",
            img2: "/images/portfolio/web/blog/img2.jpg",
            img3: "/images/portfolio/web/blog/img3.jpg",
            description: intl.formatMessage({ id: "portfolio.projects.pr3.description" })
        }
    ];
    let prid = 0;
    if (params && params.id && params.id <= projectDataDetails.length) {
        prid = params.id - 1;
    }
    let selectedProject = projectDataDetails[prid];

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    });

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    return (
        <div>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">

                        <div className="d-flex justify-content-between align-items-center">
                            <h2><FormattedMessage id="portfolio.details" /></h2>
                            <ol>
                                <li><HashLink scroll={(el) => hashnav({}, "#home", true)} to="/#home"><FormattedMessage id="menu.home" /></HashLink></li>
                                <li><FormattedMessage id="portfolio.details" /></li>
                            </ol>
                        </div>

                    </div>
                </section>

                <section id="portfolio-details" className="portfolio-details">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-8">
                                <Swiper slidesPerView={'auto'} loop={true} pagination={{
                                    el: '.swiper-pagination', type: 'bullets', clickable: true, renderBullet: function (index, className) {
                                        return '<span class="' + className + '"></span>';
                                    },
                                }} speed={400} autoplay={{ delay: 5000, disableOnInteraction: false, }} modules={[Pagination, Autoplay]}>
                                    <SwiperSlide><img src={selectedProject.img1} style={{ "width": "100%" }} alt="img" /></SwiperSlide>
                                    <SwiperSlide><img src={selectedProject.img2} style={{ "width": "100%" }} alt="img" /></SwiperSlide>
                                    <SwiperSlide><img src={selectedProject.img3} style={{ "width": "100%" }} alt="img" /></SwiperSlide>
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>

                            <div className="col-lg-4">
                                <div className="portfolio-info">
                                    <h3><FormattedMessage id="portfolio.projectinfo" /></h3>
                                    <ul>
                                        <li><strong><FormattedMessage id="portfolio.category" /></strong>: {selectedProject.type}</li>
                                        <li><strong><FormattedMessage id="portfolio.client" /></strong>: {selectedProject.client}</li>
                                        <li><strong><FormattedMessage id="portfolio.date" /></strong>: {selectedProject.date}</li>
                                    </ul>
                                </div>
                                <div className="portfolio-description">
                                    <h2>{selectedProject.name}</h2>
                                    <p>
                                        {selectedProject.description}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>
        </div>
    );
}

export default DetailsPage;