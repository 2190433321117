import React, { useState } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './Main';
import DetailsPage from './DetailsPage';
import InnerPage from './InnerPage';
import translations from "./translations";
import { IntlProvider } from 'react-intl';
import FooterAddition from './components/FooterAddition/FooterAddition';

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

export default function App(props: any) {
    let path = window.location.pathname;

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [locale, setLocale] = useState(params.get('lang') || localStorage.getItem("lang") || "en");
    function changeLanguage(locale) {
        setLocale(locale) // changes the lang when selec value changes
    }

    let messages = flattenMessages(translations[locale]);

    function flattenMessages(nestedMessages, prefix = '') {
        return Object.keys(nestedMessages).reduce((messages, key) => {
            let value = nestedMessages[key];
            let prefixedKey = prefix ? `${prefix}.${key}` : key;

            if (typeof value === 'string') {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, flattenMessages(value, prefixedKey));
            }

            return messages;
        }, {});
    }

    return (
        <Router>
            <IntlProvider locale={locale} messages={messages} defaultLocale="en">
                <Header changeLanguage={changeLanguage} hashnav={hashnav} />
                <Routes>
                    <Route path="/" element={<Main locale={locale} />} />
                    <Route path="details" element={<DetailsPage hashnav={hashnav} />}>
                        <Route path=":id" element={<DetailsPage hashnav={hashnav} />} />
                    </Route>
                    <Route path="privacypolicy" element={<InnerPage hashnav={hashnav} type="privacypolicy" />} />
                    <Route path="cookiepolicy" element={<InnerPage hashnav={hashnav} type="cookiepolicy" />} />
                    <Route path="*" element={<InnerPage type="errorpage" hashnav={hashnav} />} />
                </Routes>
                <Footer path={path} hashnav={hashnav} />
                <div id="preloader"></div>
                <FooterAddition hashnav={hashnav}/>
            </IntlProvider>
        </Router>
    );
}