import React from 'react';
import LandingIcons from '../LandingSection/LandingIcons/LandingIcons';
import Motto from '../LandingSection/Motto/Motto';

const LandingSection = () => (
    <section id="home" className="d-flex align-items-center justify-content-center" data-testid="LandingSection">
        <div className="container" data-aos="fade-up">
            <Motto />
            <LandingIcons />
        </div>
    </section>
);

export default LandingSection;
