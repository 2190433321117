import React from 'react';
import { FormattedMessage } from "react-intl";

const Motto = () => (
    <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150" data-testid="Motto">
        <div className="col-xl-6 col-lg-8">
            <h1><FormattedMessage id="title" /><span>.</span></h1>
            <h2><p><span><FormattedMessage id="motto" /></span></p></h2>
        </div>
    </div>
);

export default Motto;
