import React from 'react';
import { FormattedMessage } from "react-intl";
import { HashLink } from 'react-router-hash-link';

const Menu = (props: any) => {

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    const isMainRoute = props.path === "/" ? false : true;
    const activeClass = props.path === "/" ? "nav-link scrollto active" : "nav-link scrollto";

    return (
        <nav id="navbar" className="navbar order-last order-lg-0" data-testid="Menu">
            <ul>
                <li><HashLink className={activeClass} scroll={(el) => hashnav({}, "#home", isMainRoute)} to="/#home"><FormattedMessage id="menu.home" /></HashLink></li>
                <li><HashLink className="nav-link scrollto" scroll={(el) => hashnav({}, "#about", isMainRoute)} to="/#about"><FormattedMessage id="menu.about" /></HashLink></li>
                <li><HashLink className="nav-link scrollto" scroll={(el) => hashnav({}, "#team", isMainRoute)} to="/#team"><FormattedMessage id="menu.team" /></HashLink></li>
                <li><HashLink className="nav-link scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="menu.services" /></HashLink></li>
                <li><HashLink className="nav-link scrollto" scroll={(el) => hashnav({}, "#portfolio", isMainRoute)} to="/#portfolio"><FormattedMessage id="menu.portfolio" /></HashLink></li>
                <li><HashLink className="nav-link scrollto" scroll={(el) => hashnav({}, "#contact", isMainRoute)} to="/#contact"><FormattedMessage id="menu.contact" /></HashLink></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
    );
};

export default Menu;