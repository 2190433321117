import React from 'react';
import { FaReact, FaAngular, FaEmber, FaWpforms, FaQuestionCircle } from 'react-icons/fa';
import { SiDotnet, SiFastapi, SiPhp, SiXamarin } from 'react-icons/si';
import { MdSpaceDashboard, MdShoppingCart, MdDesignServices, MdSupportAgent, MdOutlineMoreHoriz, MdWebAsset } from 'react-icons/md';
import { DiAndroid } from 'react-icons/di';

const ServiceItem = (props: any) => {
    const getIcon = (icon: any) => {
        switch (icon) {
            case 'react':
                return <FaReact fill="#000000" size="35" />;
            case 'angular':
                return <FaAngular fill="#000000" size="35" />;
            case 'ember':
                return <FaEmber fill="#000000" size="50" />;
            case 'singlespa':
                return <MdSpaceDashboard fill="#000000" size="35" />;
            case 'dotnet':
                return <SiDotnet fill="#000000" size="35" />;
            case 'webapi':
                return <SiFastapi fill="#000000" size="35" />;
            case 'php':
                return <SiPhp fill="#000000" size="35" />;
            case 'android':
                return <DiAndroid fill="#000000" size="35" />;
            case 'xamarin':
                return <SiXamarin fill="#000000" size="35" />;
            case 'wpf':
                return <FaWpforms fill="#000000" size="35" />;
            case 'webshop':
                return <MdShoppingCart fill="#000000" size="35" />;
            case 'website':
                return <MdWebAsset fill="#000000" size="35" />;
            case 'design':
                return <MdDesignServices fill="#000000" size="35" />;
            case 'support':
                return <MdSupportAgent fill="#000000" size="35" />;
            case 'more':
                return <MdOutlineMoreHoriz fill="#000000" size="35" />;
            default:
                return <FaQuestionCircle fill="#000000" size="35" />;
        }
    }

    return (
        <div id={props.icon} className="col-lg-4 col-md-6 d-flex align-items-stretch service-item" data-testid="ServiceItem" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
                <div className="icon">{ getIcon(props.icon) }</div>
                <h4>{props.title}</h4>
                <p>{props.text}</p>
            </div>
        </div>
    )
};

export default ServiceItem;
