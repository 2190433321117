import en from "./en.json";
import hu from "./hu.json";
import ro from "./ro.json";

const translations = {
    en,
    hu,
    ro,
};

export default translations;