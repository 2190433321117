import React from 'react';
import ContactUsMap from './ContactUsMap/ContactUsMap.lazy';
import ContactUsForm from './ContactUsForm/ContactUsForm.lazy';
import { FormattedMessage, useIntl } from 'react-intl';
import convertHtmlToReact from '@hedgedoc/html-to-react';

const ContactUs = (props: any) => {
    const intl = useIntl();

    const locale: any = {
        locale: props.locale
    };

    return (
        <section id="contact" className="contact" data-testid="ContactUs">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2><FormattedMessage id="contact.title" /></h2>
                    <p><FormattedMessage id="contact.contactus" /></p>
                </div>

                <ContactUsMap {...locale}/>

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="info">
                            <div className="address">
                                <i className="bi bi-geo-alt"></i>
                                <h4><FormattedMessage id="footer.location" />:</h4>
                                <p>34A Main <FormattedMessage id="footer.street" /> <br />
                                Long Eaton, NG10 1GR <br />
                                <FormattedMessage id="footer.country" /><br /></p>
                            </div>
                            <div className="email">
                                <i className="bi bi-envelope"></i>
                                <h4><FormattedMessage id="footer.email" />:</h4>
                                <p><a href="mailto: &#x70;&#x6f;&#x70;&#x2e;&#x6c;&#x65;&#x76;&#x65;&#x6e;&#x74;&#x65;&#x40;&#x67;&#x6d;&#x61;&#x69;&#x6c;&#x2e;&#x63;&#x6f;&#x6d;"><span className="ltrText">moc.liamg@etnevel.pop</span></a></p>
                            </div>
                            <div className="phone">
                                <i className="bi bi-phone"></i>
                                <h4>{convertHtmlToReact(intl.formatMessage({ id: "footer.phone" }))}</h4>
                                <p>+<span className="ltrText">690 331 847 04</span><br />
                                +<span className="ltrText">451 815 2247 44</span></p>
                            </div>
                        </div>
                    </div>

                    <ContactUsForm {...locale} />

                </div>

            </div>
        </section>
    );
};

export default ContactUs;
