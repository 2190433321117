import React from 'react';
import { useIntl } from "react-intl";
import convertHtmlToReact from '@hedgedoc/html-to-react';

const FooterCopyright = () => {
    const intl = useIntl();
    return (
        <div className="container" data-testid="FooterCopyright">
            <div className="copyright">
                {convertHtmlToReact(intl.formatMessage({ id: "footer.copyright" }))}
            </div>
        </div>
    );
};

export default FooterCopyright;
