import React from 'react';
import ServiceItem from './ServiceItem/ServiceItem';
import { FormattedMessage, useIntl } from "react-intl";

const OurServices = () => {
    const intl = useIntl();

    const service1: any = {
        title: intl.formatMessage({ id: "services.title1" }), text: intl.formatMessage({ id: "services.text1" }), icon: "react"
    };

    const service2: any = {
        title: intl.formatMessage({ id: "services.title2" }), text: intl.formatMessage({ id: "services.text2" }), icon: "angular"
    };

    const service3: any = {
        title: intl.formatMessage({ id: "services.title3" }), text: intl.formatMessage({ id: "services.text3" }), icon: "ember"
    };

    const service4: any = {
        title: intl.formatMessage({ id: "services.title4" }), text: intl.formatMessage({ id: "services.text4" }), icon: "singlespa"
    };

    const service5: any = {
        title: intl.formatMessage({ id: "services.title5" }), text: intl.formatMessage({ id: "services.text5" }), icon: "dotnet"
    };

    const service6: any = {
        title: intl.formatMessage({ id: "services.title6" }), text: intl.formatMessage({ id: "services.text6" }), icon: "webapi"
    };

    const service7: any = {
        title: intl.formatMessage({ id: "services.title7" }), text: intl.formatMessage({ id: "services.text7" }), icon: "php"
    };

    const service8: any = {
        title: intl.formatMessage({ id: "services.title8" }), text: intl.formatMessage({ id: "services.text8" }), icon: "android"
    };

    const service9: any = {
        title: intl.formatMessage({ id: "services.title9" }), text: intl.formatMessage({ id: "services.text9" }), icon: "xamarin"
    };

    const service10: any = {
        title: intl.formatMessage({ id: "services.title10" }), text: intl.formatMessage({ id: "services.text10" }), icon: "wpf"
    };

    const service11: any = {
        title: intl.formatMessage({ id: "services.title11" }), text: intl.formatMessage({ id: "services.text11" }), icon: "webshop"
    };

    const service12: any = {
        title: intl.formatMessage({ id: "services.title12" }), text: intl.formatMessage({ id: "services.text12" }), icon: "website"
    };

    const service13: any = {
        title: intl.formatMessage({ id: "services.title13" }), text: intl.formatMessage({ id: "services.text13" }), icon: "design"
    };

    const service14: any = {
        title: intl.formatMessage({ id: "services.title14" }), text: intl.formatMessage({ id: "services.text14" }), icon: "support"
    };

    const service15: any = {
        title: intl.formatMessage({ id: "services.title15" }), text: intl.formatMessage({ id: "services.text15" }), icon: "more"
    };

    return (
        <section id="services" className="services" data-testid="OurServices">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2><FormattedMessage id="services.title" /></h2>
                    <p><FormattedMessage id="services.subtitle" /></p>
                </div>
                <div className="row">
                    <ServiceItem {...service1} />
                    <ServiceItem {...service2} />
                    <ServiceItem {...service3} />
                    <ServiceItem {...service4} />
                    <ServiceItem {...service5} />
                    <ServiceItem {...service6} />
                    <ServiceItem {...service7} />
                    <ServiceItem {...service8} />
                    <ServiceItem {...service9} />
                    <ServiceItem {...service10} />
                    <ServiceItem {...service11} />
                    <ServiceItem {...service12} />
                    <ServiceItem {...service13} />
                    <ServiceItem {...service14} />
                    <ServiceItem {...service15} />
                </div>
            </div>
        </section>
    );
};

export default OurServices;
