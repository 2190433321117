import React from 'react';
import Menu from './Menu/Menu';
import LanguagePicker from './LanguagePicker/LanguagePicker';
import {
    useLocation
} from "react-router-dom";

const Header = (props: any) => {
    let location = useLocation();

    const changeLanguage = (lang: string) => {
        props.changeLanguage(lang);
    };

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    return (
        <header id="header" className={location.pathname !== "/" ? "fixed-top header-inner-pages" : "fixed-top"} data-testid="Header">
            <div className="container d-flex align-items-center justify-content-lg-between">
                <a href="/" className="logo me-auto me-lg-0"><img src="/images/logoSD.svg" alt="SoftwareDome" className="img-fluid" /></a>
                <Menu changeLanguage={changeLanguage} path={location.pathname} hashnav={hashnav}/>
                <LanguagePicker changeLanguage={changeLanguage} />
            </div>
        </header>
    );
};

export default Header;
