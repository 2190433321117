import React from "react";
import convertHtmlToReact from "@hedgedoc/html-to-react";

const TeamMember = (props: any) => {
  let linkedin, facebook, email, certification;
  if (props.linkedin) {
    linkedin = (
      <a href={props.linkedin} target="_blank" rel="noreferrer">
        <i className="bi bi-linkedin"></i>
      </a>
    );
  } else {
    linkedin = "";
  }
  if (props.facebook) {
    facebook = (
      <a href={props.facebook} target="_blank" rel="noreferrer">
        <i className="bi bi-facebook"></i>
      </a>
    );
  } else {
    facebook = "";
  }
  if (props.email) {
    email =
      "<a href='" +
      props.email +
      "' target='_blank' rel='noreferrer'><i class='bi bi-envelope'></i></a>";
  } else {
    email = "";
  }
  if (props.certificaton && props.certificationText !== ".") {
    certification =
      "<a href='" +
      props.certificaton +
      "' target='_blank' rel='noreferrer'>" +
      props.certificationText +
      "</a>";
  } else {
    certification = "";
  }

  return (
    <div
      className="col-lg-3 col-md-6 d-flex align-items-stretch"
      data-testid="TeamMember"
    >
      <div className="member" data-aos="fade-up" data-aos-delay="100">
        <div className="member-img">
          <img src={props.img} className="img-fluid" alt={props.name} />
          <div className="social">
            {convertHtmlToReact(email)}
            {facebook}
            {linkedin}
          </div>
        </div>
        <div className="member-info">
          <h4>{props.name}</h4>
          <span>{props.jobTitle}</span>
          {certification.length > 0 && (
            <span>{convertHtmlToReact(certification)}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
