import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import "aos/dist/aos.css";
import App from './App';
// Styles must use direct files imports
import 'swiper/swiper-bundle.min.css'; // core Swiper

/**
 * Scrolls to an element with header offset
 */
const scrollto = (el) => {
    let header = select('#header');
    let offset = header.offsetHeight;

    let elementPos = select(el).offsetTop;
    window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
    });
};

function removeHash() {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in window.history)
        window.history.replaceState("", document.title, loc.pathname + loc.search);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

function hashnav(e, hash = this.hash, fromAnotherPage = false) {
    if (hash.length > 0 && select(hash)) {
        e.preventDefault && e.preventDefault();

        let navbar = select('#navbar');
        if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            let navbarToggle = select('.mobile-nav-toggle');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
        }
        removeHash();
        if (fromAnotherPage) {
            setTimeout(() => {
                scrollto(hash);
                navbarlinksActive();
            }, 100);
        } else {
            scrollto(hash);
        }

    } else {
        setTimeout(() => {
            let navbarlinks = select('#navbar .scrollto', true);
            navbarlinks.forEach(navbarlink => {
                navbarlink.classList.remove('active');
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
        
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App hashnav={hashnav}/>
    </React.StrictMode>,
    document.getElementById('root')
);

/**
 * Easy selector helper function
 */

const select = (el, all = false) => {
    el = el.trim();
    if (all) {
        return [...document.querySelectorAll(el)];
    } else {
        return document.querySelector(el);
    }
};

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
        if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener));
        } else {
            selectEl.addEventListener(type, listener);
        }
    }
};

/**
 * Easy on scroll event listener 
 */
const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener);
};

window.addEventListener('load', () => {
    AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
    });
});

/**
 * Navbar links active state on scroll
 */
let navbarlinks = select('#navbar .scrollto', true);
const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active');
        } else {
            navbarlink.classList.remove('active');
        }
    });
};
window.addEventListener('load', navbarlinksActive);
onscroll(document, navbarlinksActive);

/**
   * Mobile nav toggle
   */
on('click', '.mobile-nav-toggle', function (e) {
    select('#navbar').classList.toggle('navbar-mobile');
    this.classList.toggle('bi-list');
    this.classList.toggle('bi-x');
})

/**
 * Mobile nav dropdowns activate
 */
on('click', '.navbar .dropdown > a', function (e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault();
        this.nextElementSibling.classList.toggle('dropdown-active');
    }
}, true);

/**
 * Scroll with ofset on links with a class name .scrollto
 */
on('click', '.scrollto', hashnav, true);

/**
 * Scroll with ofset on page load with hash links in the url
 */
window.addEventListener('load', () => {
    if (window.location.hash) {
        if (select(window.location.hash)) {
            scrollto(window.location.hash);
        }
    }
});

let preloader = select('#preloader');
if (preloader) {
    window.addEventListener('load', () => {
        preloader.remove();
    });
}

/**
 * Toggle .header-scrolled class to #header when page is scrolled
 */
let selectHeader = select('#header');
if (selectHeader) {
    const headerScrolled = () => {
        if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled');
        } else {
            selectHeader.classList.remove('header-scrolled');
        }
    };
    window.addEventListener('load', headerScrolled);
    onscroll(document, headerScrolled);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
