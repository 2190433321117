import React, { useState } from 'react';
import 'flag-icon-css/css/flag-icons.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCookieConsentValue } from "react-cookie-consent";

const LanguagePicker = (props: any) => {
    const [lang, setLang] = useState(localStorage.getItem("lang") || "en");
    const intl = useIntl();
    const getFullLang = (lang: string) => {
        switch (lang) {
            case "en":
                return intl.formatMessage({ id: "menu.language.en"});
            case "ro":
                return intl.formatMessage({ id: "menu.language.ro" });
            case "hu":
                return intl.formatMessage({ id: "menu.language.hu" });
            default:
                return intl.formatMessage({ id: "menu.language.en" });
        }
    };

    const setLanguage = (_lang: string) => {
        if (getCookieConsentValue("dataconsent") === "true") {
            localStorage.setItem("lang", _lang);
        }
        setLang(_lang);
        props.changeLanguage(_lang);
    };

    const toggleLanguageVisibility = () => {
        if (document.getElementsByClassName("language-dropdown-ul")[0].classList.contains("show")) {
            document.getElementsByClassName("language-dropdown-ul")[0].classList.remove("show");
        } else {
            document.getElementsByClassName("language-dropdown-ul")[0].classList.add("show");
        }
    }

    return (
        <nav id="navbar-lang" className="navbar-dd order-last order-lg-0 lang-dd" data-testid="LanguagePicker">
            <ul>
                <li className="dropdown language-dropdown" onClick={(e) => { toggleLanguageVisibility(); }}><a href="/#" onClick={(e) => { e.preventDefault(); }}><span><span className={`flag-icon flag-icon-${lang.replace("en","gb")}`}></span>{getFullLang(lang)}</span><i className="bi bi-chevron-down"></i></a>
                    <ul className="language-dropdown-ul">
                        <li><a href="/#" onClick={(e) => { e.preventDefault(); setLanguage("en"); }}><span className="flag-icon flag-icon-gb"></span><FormattedMessage id="menu.language.en" /></a></li>
                        <li><a href="/#" onClick={(e) => { e.preventDefault(); setLanguage("hu"); }}><span className="flag-icon flag-icon-hu"></span><FormattedMessage id="menu.language.hu" /></a></li>
                        <li><a href="/#" onClick={(e) => { e.preventDefault(); setLanguage("ro"); }}><span className="flag-icon flag-icon-ro"></span><FormattedMessage id="menu.language.ro" /></a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default LanguagePicker;