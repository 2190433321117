import React from 'react';

const TestimonialItem = (props: any) => (
    <div className="swiper-slide" data-testid="TestimonialItem">
        <div className="testimonial-item">
            <img src={props.img} className="testimonial-img" alt="" />
            <h3>{props.name}</h3>
            <h4>{props.jobTitle}</h4>
            <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                {props.text}
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
        </div>
    </div>
);

export default TestimonialItem;
