import React from 'react';
import styles from './AboutUs.module.css';
import ExistingClients from './ExistingClients/ExistingClients';
import MoreAbout from './MoreAbout/MoreAbout';
import { FormattedMessage } from "react-intl";

const AboutUs = () => (
    <div className={styles.AboutUs} data-testid="AboutUs">
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                        <img src="images/about.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                        <h3><FormattedMessage id="aboutus.general.title" /><span>.</span></h3>
                        <p className="fst-italic">
                            <FormattedMessage id="aboutus.general.text1" />
                        </p>
                        <ul>
                            <li><i className="ri-check-double-line"></i> <FormattedMessage id="aboutus.general.bullet1" /></li>
                            <li><i className="ri-check-double-line"></i> <FormattedMessage id="aboutus.general.bullet2" /></li>
                            <li><i className="ri-check-double-line"></i> <FormattedMessage id="aboutus.general.bullet3" /></li>
                        </ul>
                        <p>
                            <FormattedMessage id="aboutus.general.text2" /> 
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <ExistingClients />
        <MoreAbout />
    </div>
);

export default AboutUs;
