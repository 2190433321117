import React from 'react';
import { FormattedMessage } from "react-intl";

const MoreAbout = () => {

    return (
        <section id="features" className="features" data-testid="MoreAbout">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="image col-lg-6" style={{
                        backgroundImage: `url("/images/features.jpg")`
                    }} data-aos="fade-right"></div>
                    <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
                        <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
                            <h3><FormattedMessage id="aboutus.more.title" /></h3>
                            <p><FormattedMessage id="aboutus.more.text" /></p>
                        </div>
                        <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-cube-alt"></i>
                            <h4><FormattedMessage id="aboutus.more.more2title" /></h4>
                            <p><FormattedMessage id="aboutus.more.more2text" /></p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-conversation"></i>
                            <h4><FormattedMessage id="aboutus.more.more1title" /></h4>
                            <p><FormattedMessage id="aboutus.more.more1text" /></p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
                            <i className="bx bx-smile"></i>
                            <h4><FormattedMessage id="aboutus.more.more3title" /></h4>
                            <p><FormattedMessage id="aboutus.more.more3text" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MoreAbout;
