import React from "react";
import TeamMember from "./TeamMember/TeamMember";
import { FormattedMessage, useIntl } from "react-intl";

const OurTeam = () => {
  const intl = useIntl();

  const firstTeamMember: any = {
    name: intl.formatMessage({ id: "team.name3" }),
    jobTitle: intl.formatMessage({ id: "team.jobtitle3" }),
    img: "/images/team/kati.jpg",
    linkedin: "https://www.linkedin.com/in/katalin-gall-80b927118/",
    facebook: "https://www.facebook.com/katka.gall.5",
    email:
      "mailto: &#107;&#097;&#116;&#097;&#108;&#105;&#110;&#046;&#103;&#097;&#108;&#108;&#064;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#100;&#111;&#109;&#101;&#046;&#097;&#112;&#112;",
    certificaton:
      "/images/team/" + intl.formatMessage({ id: "team.certification3" }),
    certificationText: intl.formatMessage({
      id: "team.certificationLinkText3",
    }),
  };
  const secondTeamMember: any = {
    name: intl.formatMessage({ id: "team.name4" }),
    jobTitle: intl.formatMessage({ id: "team.jobtitle4" }),
    img: "/images/team/reka.jpg",
    facebook: "https://www.facebook.com/rekasarolta.gall",
    linkedin: "https://www.linkedin.com/in/r%C3%A9ka-g%C3%A1ll-810224225/",
    email:
      "mailto: &#114;&#101;&#107;&#097;&#046;&#103;&#097;&#108;&#108;&#064;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#100;&#111;&#109;&#101;&#046;&#097;&#112;&#112;",
    certificaton:
      "/images/team/" + intl.formatMessage({ id: "team.certification4" }),
    certificationText: intl.formatMessage({
      id: "team.certificationLinkText4",
    }),
  };

  const thirdTeamMember: any = {
    name: intl.formatMessage({ id: "team.name1" }),
    jobTitle: intl.formatMessage({ id: "team.jobtitle1" }),
    img: "/images/team/levi.jpg",
    linkedin: "https://www.linkedin.com/in/levente-pop-22544a20",
    facebook: "https://www.facebook.com/levente.pop",
    email:
      "mailto: &#108;&#101;&#118;&#101;&#110;&#116;&#101;&#046;&#112;&#111;&#112;&#064;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#100;&#111;&#109;&#101;&#046;&#097;&#112;&#112;",
    certificaton:
      "/images/team/" + intl.formatMessage({ id: "team.certification1" }),
    certificationText: intl.formatMessage({
      id: "team.certificationLinkText1",
    }),
  };
  const forthTeamMember: any = {
    name: intl.formatMessage({ id: "team.name2" }),
    jobTitle: intl.formatMessage({ id: "team.jobtitle2" }),
    img: "/images/team/laci.jpg",
    facebook: "https://www.facebook.com/gall.attila.1",
    linkedin: "https://www.linkedin.com/in/laszlo-gall-259b34225/",
    email:
      "mailto: &#108;&#097;&#115;&#122;&#108;&#111;&#046;&#103;&#097;&#108;&#108;&#064;&#115;&#111;&#102;&#116;&#119;&#097;&#114;&#101;&#100;&#111;&#109;&#101;&#046;&#097;&#112;&#112;",
    certificaton:
      "/images/team/" + intl.formatMessage({ id: "team.certification2" }),
    certificationText: intl.formatMessage({
      id: "team.certificationLinkText2",
    }),
  };

  return (
    <section id="team" className="team" data-testid="OurTeam">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>
            <FormattedMessage id="team.title" />
          </h2>
          <p>
            <FormattedMessage id="team.subtitle" />
          </p>
        </div>
        <div className="row">
          <TeamMember {...firstTeamMember} />
          <TeamMember {...secondTeamMember} />
          <TeamMember {...thirdTeamMember} />
          <TeamMember {...forthTeamMember} />
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
