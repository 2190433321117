import React from 'react';
import LandingSection from './components/LandingSection/LandingSection';
import AboutUs from './components/AboutUs/AboutUs';
import OurTeam from './components/OurTeam/OurTeam';
import OurServices from './components/OurServices/OurServices';
import AskOffer from './components/OurServices/AskOffer/AskOffer';
import Portfolio from './components/Portfolio/Portfolio';
import PortfolioInfo from './components/Portfolio/PortfolioInfo/PortfolioInfo';
import Testimonials from './components/Testimonials/Testimonials';
import ContactUs from './components/ContactUs/ContactUs';

const Main = (props: any) => {

    return (
        <div>
            <LandingSection />
            <main id="main">
                <AboutUs />
                <OurTeam />
                <OurServices />
                <AskOffer />
                <Portfolio />
                <PortfolioInfo />
                <Testimonials />
                <ContactUs locale={props.locale} />
            </main>
        </div>
    );
}

export default Main;