import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PureCounter from "../../../external/purecounter.js";

const PortfolioInfo = () => {
  useEffect(() => {
    const pure = new PureCounter();
    pure.parseValue(1);
  });

  return (
    <section id="counts" className="counts" data-testid="PortfolioInfo">
      <div className="container" data-aos="fade-up">
        <div className="row no-gutters">
          <div
            className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
            data-aos="fade-right"
            data-aos-delay="100"
          ></div>
          <div
            className="col-xl-7 ps-lg-5 pe-lg-1 d-flex align-items-stretch"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="content d-flex flex-column justify-content-center">
              <h3>
                <FormattedMessage id="portfolio.info.title" />
              </h3>
              <p>
                <FormattedMessage id="portfolio.info.text" />
              </p>
              <div className="row">
                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-emoji-smile"></i>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="4"
                      data-purecounter-duration="3"
                      className="purecounter"
                    ></span>
                    <p>
                      <strong>
                        <FormattedMessage id="portfolio.info.clientstitle" />
                      </strong>
                      <FormattedMessage id="portfolio.info.clientstext" />
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-clock"></i>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="15"
                      data-purecounter-duration="3"
                      className="purecounter"
                    ></span>
                    <span className="purecounterBox">+</span>
                    <p>
                      <strong>
                        <FormattedMessage id="portfolio.info.experiencetitle" />
                      </strong>
                      <FormattedMessage id="portfolio.info.experiencetext" />
                    </p>
                  </div>
                </div>

                <div className="col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-award"></i>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="20"
                      data-purecounter-duration="3"
                      className="purecounter"
                    ></span>
                    <span className="purecounterBox">+</span>
                    <p>
                      <strong>
                        <FormattedMessage id="portfolio.info.technologiestitle" />
                      </strong>
                      <FormattedMessage id="portfolio.info.technologiestext" />
                    </p>
                  </div>
                </div>

                {/*<div className="col-md-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="bi bi-journal-richtext"></i>
                                        <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="2" className="purecounter"></span>
                                        <p><strong>Projects</strong> adipisci atque cum quia aspernatur totam laudantium et quia dere tan</p>
                                    </div>
                                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioInfo;
