import React from 'react';

const FooterSocialmedia = () => (
    <div className="social-links mt-3" data-testid="FooterSocialmedia">
        <a href="https://www.facebook.com/softwaredomeapp" target="_blank" rel="noreferrer" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="https://www.linkedin.com/company/softwaredome-app/?viewAsMember=true" target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
        <a href="https://www.instagram.com/softwaredome/" target="_blank" rel="noreferrer" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a href="https://twitter.com/softwaredome" target="_blank" rel="noreferrer" className="twitter"><i className="bx bxl-twitter"></i></a>
    </div>
);

export default FooterSocialmedia;
