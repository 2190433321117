import React, { lazy, Suspense } from 'react';

const LazyContactUsMap = lazy(() => import('./ContactUsMap'));

const ContactUsMap = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyContactUsMap {...props} />
  </Suspense>
);

export default ContactUsMap;
