import React from 'react';
import { useIntl } from "react-intl";
import convertHtmlToReact from '@hedgedoc/html-to-react';

const Cookiepolicy = () => {

    const intl = useIntl();

    return (
        <div data-testid="Cookiepolicy">
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title1" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p1" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title2" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p2" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title3" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p3" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title4" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p4" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title5" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p5" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title6" }))}
            </h2>
            <p className="cookie-policy-p">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.p6" }))}
            </p>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title7" }))}
            </h2>
            <div className="overflow-table">
                <table>
                    <thead>
                        <tr>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.name" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.type" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.duration" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.description" }))}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_grecaptcha</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.localstorage" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.forever" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc1" }))}</td>
                        </tr>
                        <tr>
                            <td>lang</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.localstorage" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.forever" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc2" }))}</td>
                        </tr>
                        <tr>
                            <td>dataconsent</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.cookie" }))}</td>
                            <td>2 {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.years" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc3" }))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 className="cookie-policy-h2">
                {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.title8" }))}
            </h2>
            <div className="overflow-table">
                <table>
                    <thead>
                        <tr>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.name" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.type" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.secure" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.duration" }))}</th>
                            <th>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.description" }))}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_gat</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.cookie" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.yes" }))}</td>
                            <td>1 {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.minute" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc4" }))}</td>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.cookie" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.yes" }))}</td>
                            <td>2 {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.years" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc5" }))}</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.cookie" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.yes" }))}</td>
                            <td>1 {convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.day" }))}</td>
                            <td>{convertHtmlToReact(intl.formatMessage({ id: "cookiepolicy.desc6" }))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Cookiepolicy;
