import React, { useEffect } from 'react';
import DataConsent from '../DataConsent/DataConsent';

import {
    useLocation
} from "react-router-dom";

const FooterAddition = (props: any) => {
    let location = useLocation();
    let url;

    /**
     * Back to top button
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener);
    };

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    useEffect(() => {
        let backtotop = select('.back-to-top');
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active');
                } else {
                    backtotop.classList.remove('active');
                }
            };
            window.addEventListener('load', toggleBacktotop);
            onscroll(document, toggleBacktotop);
        }
    });

    if (location.pathname === "/") {
        url = <a href="/#home" className="scrollto back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>;
    } else { url = ""; }
    return (
        <div className="fixed-bottom">
            <div>{url}</div>
            <DataConsent hashnav={hashnav}/>
        </div>
    );
};

export default FooterAddition;
