import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import {
    useLocation
} from "react-router-dom";

const FooterLinks = (props: any) => {
    let location = useLocation();

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };
    const isMainRoute = location.pathname === "/" ? false : true;

    return (
        <div data-testid="FooterLinks" className="col-lg-6 col-md-6 row">
            <div className="col-lg-6 col-md-6 footer-links">
                <h4><FormattedMessage id="footer.useful" /></h4>
                <ul>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#home", isMainRoute)} to="/#home"><FormattedMessage id="menu.home" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#about", isMainRoute)} to="/#about"><FormattedMessage id="menu.about" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="menu.services" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#team", isMainRoute)} to="/#team"><FormattedMessage id="menu.team" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "", isMainRoute)} to="/privacypolicy"><FormattedMessage id="menu.privacy" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "", isMainRoute)} to="/cookiepolicy"><FormattedMessage id="menu.cookiepolicy" /></HashLink></li>
                </ul>
            </div>

            <div className="col-lg-6 col-md-6 footer-links">
                <h4><FormattedMessage id="footer.mainservices" /></h4>
                <ul>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.webdev" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.desktopdev" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.mobiledev" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.webshops" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.design" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#services", isMainRoute)} to="/#services"><FormattedMessage id="footer.support" /></HashLink></li>
                    <li><i className="bx bx-chevron-right"></i><HashLink className="scrollto" scroll={(el) => hashnav({}, "#contact", isMainRoute)} to="/#contact"><FormattedMessage id="footer.callformore" /></HashLink></li>
                </ul>
            </div>
        </div>
    );
};

export default FooterLinks;
