import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from "react-intl";
import ErrorPage from "./components/Errorpage/Errorpage";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";
import Cookiepolicy from './components/Cookiepolicy/Cookiepolicy';

const InnerPage = (props: any) => {

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    function renderSwitch(param) {
        switch (param) {
            case 'errorpage':
                return <ErrorPage />;
            case 'privacypolicy':
                return <Privacypolicy />;
            case 'cookiepolicy':
                return <Cookiepolicy />;
            default:
                return '';
        }
    };

    return (
        <div>
            <main id="main">
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>
                                <FormattedMessage id={props.type + ".title"} />
                            </h2>
                            <ol>
                                <li><HashLink scroll={(el) => hashnav({}, "#home", true)} to="/#home"><FormattedMessage id="menu.home" /></HashLink></li>
                                <li>
                                    <FormattedMessage id={props.type + ".title"} />
                                </li>
                            </ol>
                        </div>
                    </div>
                </section>
                <section className="inner-page">
                    <div className="container">
                        {renderSwitch(props.type)}
                    </div>
                </section>
            </main>
        </div>
    );
}

export default InnerPage;