import React from 'react';
import { FormattedMessage } from "react-intl";
import { FaReact, FaAngular, FaEmber, FaWpforms } from 'react-icons/fa';
import { SiCsharp } from 'react-icons/si';

const LandingIcons = () => (
    <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
        <div><h2><FormattedMessage id="landingicons.title1" /></h2></div>
        <div className="col-xl-2 col-md-4">
            <div className="icon-box">
                <div className="landing-icon"><FaReact fill="#e09522" size="35" /></div>
                <h3><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.react" /></a></h3>
            </div>
        </div>
        <div className="col-xl-2 col-md-4">
            <div className="icon-box">
                <div className="landing-icon"><FaAngular fill="#e09522" size="35" /></div>
                <h3><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.angular" /></a></h3>
            </div>
        </div>
        <div className="col-xl-2 col-md-4">
            <div className="icon-box">
                <div className="landing-icon emberjs"><FaEmber fill="#e09522" size="60" /></div>
                <h3><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.ember" /></a></h3>
            </div>
        </div>
        <div className="col-xl-2 col-md-4">
            <div className="icon-box">
                <div className="landing-icon"><SiCsharp fill="#e09522" size="30" /></div>
                <h3><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.csharp" /></a></h3>
            </div>
        </div>
        <div className="col-xl-2 col-md-4">
            <div className="icon-box">
                <div className="landing-icon"><FaWpforms fill="#e09522" size="35" /></div>
                <h3><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.wpf" /></a></h3>
            </div>
        </div>
        <div><h2><FormattedMessage id="landingicons.title2" /><a className="scrollto" href="/#services"><FormattedMessage id="landingicons.title3" /></a>...</h2></div>
    </div>
);

export default LandingIcons;
