import React from 'react';
import { HashLink } from 'react-router-hash-link';

const PortfolioItem = (props: any) => {

    return (
        <div className={"col-lg-4 col-md-6 portfolio-item " + props.filtertype} data-testid="PortfolioItem">
            <div className="portfolio-wrap">
                <img src={props.img} className="img-fluid" alt={props.name} />
                <div className="portfolio-info">
                    <h4>{props.name}</h4>
                    <p>{props.type}</p>
                    <div className="portfolio-links">
                        <a href={props.img} data-gallery="portfolioGallery" className="portfolio-lightbox" title={props.name}><i className="bx bx-plus"></i></a>
                        <HashLink to={props.link + "/" + props.id} title="More Details"><i className="bx bx-link"></i></HashLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioItem;
