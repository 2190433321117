import React from 'react';
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import { useIntl } from "react-intl";
import {
    useLocation
} from "react-router-dom";

const DataConsent = (props: any) => {
    const intl = useIntl();
    let location = useLocation();

    const hashnav = (e, hash, fromAnotherPage) => {
        props.hashnav(e, hash, fromAnotherPage);
    };

    const isMainRoute = location.pathname === "/" ? false : true;

    return (
        <div data-testid="DataConsent">
            <CookieConsent
                location="bottom"
                buttonText={intl.formatMessage({ id: "dataconsent.button" })}
                cookieName="dataconsent"
                style={{ background: "#000" }}
                buttonStyle={{ color: "#000", fontSize: "13px" }}
                expires={730}
            >
                <FormattedMessage id="dataconsent.text" /> <HashLink className="scrollto" scroll={(el) => hashnav({}, "", isMainRoute)} to="/cookiepolicy"><FormattedMessage id="menu.cookiepolicy" /></HashLink>
            </CookieConsent>
        </div>
    );
};

export default DataConsent;
